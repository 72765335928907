<template>
    <div class="h-full">
        <fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange" @onPageChange="onPageChange" :queryParam="queryParam">
            <div class="h-handle-button" slot="buttonGroup">
                <span>
                    <el-button type="primary" size="small" @click="add" v-right-code="'Rulemember:Init'">新增</el-button>
                    <el-button type="primary" size="small" @click="remove" v-right-code="'Rulemember:Delete'">删除</el-button>
                    <el-button type="primary" size="small" @click="setAvailableStatus" v-right-code="'Rulemember:Setdisabledstatus'">启用</el-button>
                    <el-button type="primary" size="small" @click="setDisabledStatus" v-right-code="'Rulemember:Setdisabledstatus'">停用</el-button>
                </span>
            </div>
            <p slot="elList">
                <el-table ref="projectListTable" :data="dataSource.Result" border @selection-change="handleSelectionChange" highlight-current-row>
                    <el-table-column type="selection" width="55" v-if="!config.isDetailDisplay"></el-table-column>
                    <el-table-column v-for="(col,index,count)  in dataSource.ColDefs.BodyFieldParams" show-overflow-tooltip="true"
                                     :key="index"
                                     :prop="col.FieldName"
                                     :label="col.DisplayName"
                                     :render-header="bindFilter(queryParam,col)"
                                     :fixed="index==0&&(!config||!config.isDetailDisplay)"
                                     :min-width="(!config||!config.isDetailDisplay)?parseInt(col.Width):0"
                                     v-if="col.Visible&&(!config||!config.isDetailDisplay||(config.isDetailDisplay&&index<2))">
                         <template slot-scope="scope">
                            <span v-if="col.FieldName==='RuleName'"><span type="text" @click="rowClick(scope.row)" style="text-decoration:underline;color:#1874CD;cursor:pointer">{{ scope.row[col.FieldName] }}</span></span>
                            <span v-else>  {{ scope.row[col.FieldName] }}</span>
                        </template> 
                    </el-table-column>
                    <!-- <el-table-column label="操作" :min-width="80" v-if="!config.isDetailDisplay">
                        <template slot-scope="scope">
                            <el-button @click="setWarehouseProjectStatus(scope.row)" v-if="scope.row.WarehouseProjectStatus==100" v-right-code="'Manage_Right_Commerce_WarehouseProject_StartEndWarehouseProject'" size="small" type="text">停用</el-button>
                            <el-button @click="setWarehouseProjectStatus(scope.row)" v-else v-right-code="'Manage_Right_Commerce_WarehouseProject_StartEndWarehouseProject'" size="small" type="text">启用</el-button>
                        </template>
                    </el-table-column> -->
                    <!--<el-table-column label="操作" :min-width="80" v-if="!config.isDetailDisplay">
                        <template slot-scope="scope">
                            <el-button @click="rowClick(scope.row)" size="small" type="text">查看详情</el-button>
                        </template>
                    </el-table-column>-->
                </el-table>
            </p>
        </fixed-list>
    </div>
</template>
<script>
    export default {
        mounted() {
            this.Utils.lazy(() => {
                var _this = this;
                this.Event.$on("reloadRuleOfOrderAuditList", () => this.reloadPageList());
                this.Event.$on("onAddRuleOfOrderAudit", () => this.add());
            });
            this.initialize();
        },
        data() {
            return {
                multipleSelection: [],
                queryParam: {
                    PageIndex: 1,
                    PageSize: 10,
                },
                dataSource: {
                    ColDefs: {},
                    Result: [],
                    TotalCount: 0
                },

            }
        },
        props: {
            config: {
                isDetailDisplay: false,
                isButtonlDisplay: false,
            },
            option: {},
        },
        methods: {
            setAvailableStatus() {
                var _this = this;
                if (this.multipleSelection == null || this.multipleSelection.length == 0) {
                    this.Utils.messageBox(`未选择数据.`, "error");
                    return false;
                }

                var ids = _this.Utils.selectionsToArr(
                    _this.multipleSelection,
                    "RuleWarehouseID"
                );

                _this.$confirm(`确定启用规则吗, 是否继续?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    _this.$ajax.send("omsapi/rulemember/setavailableStatus", "post", ids, (data) => {
                        if (data.IsSuccess) {
                            _this.Utils.messageBox(data.OperationDesc, "success");
                            _this.initialize();
                        } else {
                            _this.Utils.messageBox(data.OperationDesc, "error");
                        }
                    });
                }).catch(() => {
                    _this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
            },
            setDisabledStatus() {
                var _this = this;
                if (this.multipleSelection == null || this.multipleSelection.length == 0) {
                    this.Utils.messageBox(`未选择数据.`, "error");
                    return false;
                }

                var ids = _this.Utils.selectionsToArr(
                    _this.multipleSelection,
                    "RuleWarehouseID"
                );

                _this.$confirm(`确定停用规则吗, 是否继续?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    _this.$ajax.send("omsapi/rulemember/setdisabledStatus", "post", ids, (data) => {
                        if (data.IsSuccess) {
                            _this.Utils.messageBox(data.OperationDesc, "success");
                            _this.initialize();
                        } else {
                            _this.Utils.messageBox(data.OperationDesc, "error");
                        }
                    });
                }).catch(() => {
                    _this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
            },
            searchProject: function () {
                this.onPageChange(this.queryParam);
            },
            reloadPageList: function () {
                this.initialize();
            },
            initialize() {
                this.onPageChange(this.queryParam);
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
                this.$emit("onCheckRow", this.multipleSelection);
            },
            onPageChange(param) {
                var _this = this;
                this.queryParam = param;
                this.$ajax.query("omsapi/rulemember/getlist", "post", this.queryParam, data => {
                    _this.dataSource = data;
                });
            },
            onDataSourceChange(ds) {
                var _this = this;

                _this.dataSource = {
                    ColDefs: {
                        BodyFieldParams: []
                    },
                    Result: [],
                    TotalCount: 0
                };
                _this.$nextTick(function () {
                    _this.dataSource = ds;

                });

            },
            rowClick(row) {
                this.$ajax.send("omsapi/rulemember/getById", "get", { id: row.RuleMemberID }, (data) => {
                      if(data.Result!=null)
                    {
                        data.Result.RuleOrderAssignStatus=data.Result.RuleOrderAssignStatus.toString();
                    }
                    this.Event.$emit("clearEditFormofOrderAudit");
                    this.onChangeEditDataSource(data.Result);
                });
            },
            add() {
                this.$ajax.send("omsapi/rulemember/init", "get", {}, (data) => {
                     if(data.Result!=null)
                    {
                        data.Result.RuleOrderAssignStatus=data.Result.RuleOrderAssignStatus.toString();
                    }
                    this.onChangeEditDataSource(data.Result);
                });
            },
            remove() {
                if (this.multipleSelection == null || this.multipleSelection.length == 0) {
                    this.Utils.messageBox(`未选择规则.`, "error");
                    return false;
                }
                var This = this;
                var ids = This.Utils.selectionsToArr(This.multipleSelection, "RuleMemberID");
                
                this.Utils.confirm({
                    title: "确认",
                    content: "是否删除规则"
                }, () => {
                    
                    this.$ajax.send("omsapi/rulemember/delete", "post", ids, (data) => {
                        This.onPageChange(This.queryParam);
                        This.Utils.messageBox("操作成功.", "success");
                    });
                }, () => {
                    This.Utils.messageBox("已取消操作.", "info");
                });
            },
            onChangeEditDataSource(data) {
                this.config.isDetailDisplay = true;
                this.$emit("onChangeEditDataSource", data);
            },
        },
    }
</script>
<style>
</style>